import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Switch from '@mui/material/Switch'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import FormControlLabel from '@mui/material/FormControlLabel'
import DeleteIcon from '@mui/icons-material/Delete'
import ConfirmDialog from '../../../../components/dialogs/ConfirmDialog'
import JobRun from '../../../../components/views/JobRun'
import { RED_SUPPORT_EMAIL } from '../../../../stringConstants'
import Accordion from './Accordion'
import { styled } from '@mui/material'

const Header = styled('div')({
  display: 'grid',
  width: '100%',
  borderBottomWidth: '2px',
  borderBottomColor: '#DEDEDE',
  alignItems: 'center',
  gridGap: '12px',
  gridTemplateColumns: '440px 108px 1fr auto auto 48px'
})

const SinceCreatedDateText = styled(Typography)({
  fontWeight: 'bold',
  fontSize: '0.8rem'
})

function diffDates (date) {
  if (date === null) {
    return '0 hours ago'
  }

  const today = moment()
  const dateOfVisit = moment(date)
  const daysDiff = today.diff(dateOfVisit, 'days')

  if (daysDiff === 0) {
    return `${today.diff(dateOfVisit, 'hours')} hours ago`
  }

  if (daysDiff <= 28) {
    return `${today.diff(dateOfVisit, 'days')} days ago`
  }

  return `${today.diff(dateOfVisit, 'months')} months ago`
}

function getRunStatus (job) {
  const tableNames = job.tableNames.join(',').toLowerCase()
  const isJobRunning = jobRunning(job.finished, job.createdDate)

  if (job.errorMessage) {
    return `Failed: ${job.errorMessage}`
  }

  if (!job.finished) {
    return isJobRunning ? 'Running' : `Job failed with an unknown error. Please contact ${RED_SUPPORT_EMAIL} for additional help.`
  }

  if ((!tableNames.includes('_exp')) && (tableNames.includes('_err'))) {
    return 'Succeeded: Please See Error Table For Details'
  }

  if (!tableNames) {
    return 'Failed: Please Contact RED Development Team';
  }

  return 'Succeeded'
}

const jobRunning = (finished, createdDate) => {
  const jobCreatedDate = moment(createdDate)
  const now = moment()
  const expectedRunTimeExpired = now.diff(jobCreatedDate, 'hours') > 24
  return !finished && !expectedRunTimeExpired
}

function RunCard (props) {
  const isOfficialJobId = props.officialJobId === props.job.id
  const canSelectOfficialJob = props.job.finished && !props.job.errorMessage

  const canShowOfficialSwitch = (
    !props.updatingId ||
    props.updatingId !== props.job.id
  )

  const isJobRunning = jobRunning(props.job.finished, props.job.createdDate)
  const runStatus = getRunStatus(props.job)
  const [openDialog, setOpenDialog] = React.useState(false)

  const handlePopulateButtonClick = React.useCallback(event => {
    event.stopPropagation()
    props.onPopulate(props.job)
  }, [props])

  const handleSetOfficial = React.useCallback(event => {
    event.stopPropagation()
    props.onToggle(props.job)
  }, [props])

  const handleDeleteButtonClick = React.useCallback(event => {
    event.stopPropagation()
    setOpenDialog(true)
  }, [])

  const handleConfirmDeleteClick = React.useCallback(() => {
    setOpenDialog(false)
    props.onDelete(props.job)
  }, [props])

  const handleCancelDeleteClick = React.useCallback(() => {
    setOpenDialog(false)
  }, [])

  return <Accordion
    data-testid='accordion'
    isDisabled={props.isDisabled}
    summarySlot={
      <Header>
        <Grid container spacing={2} alignItems='center'>
          <Grid item>
            <Typography data-testid={`label-tag-${props.job.parameters.tag}-${props.job.id}`}>
              <strong>Tag:</strong> {props.job.parameters.tag}
            </Typography>
            <Typography data-testid={`label-runName-${props.job.id}`}>
              Run Name: {props.job.id}
            </Typography>
            </Grid>
        </Grid>

        <SinceCreatedDateText data-testid='label-ago'>
          {diffDates(props.job.createdDate)}
        </SinceCreatedDateText>

        <Grid container spacing={2} alignItems='center'>
          <Grid item>
            <Typography data-testid={`label-status-${props.job.id}`}>
              {runStatus}
            </Typography>
          </Grid>

          <Grid item>
            {isJobRunning && (
              <CircularProgress
                data-testid='progress-running'
                align='right'
              />
            )}
          </Grid>
        </Grid>

        {canSelectOfficialJob && !props.isDisabled
          ? <FormControlLabel
            label="Official"
            control={canShowOfficialSwitch
              ? <Switch
                data-testid={`switch-official-${props.job.id}`}
                disabled={!!props.updatingId}
                checked={isOfficialJobId}
                onClick={handleSetOfficial}
              />
              : <CircularProgress data-testid='progress-official' />
            }
          />
          : <div></div>
        }

        <Button
          data-testid={`button-populate-${props.job.id}`}
          variant="outlined"
          color="primary"
          autoFocus
          disabled={props.deleting}
          onClick={handlePopulateButtonClick}
        >Populate
        </Button>

        {!props.isDisabled
          ? <div>
            {!props.deleting
              ? <IconButton
                data-testid={`button-delete-${props.job.id}`}
                align='right'
                color="primary"
                disabled={isJobRunning || isOfficialJobId}
                onClick={handleDeleteButtonClick}
                size="large"
              >
                <DeleteIcon />
              </IconButton>
              : <CircularProgress
                data-testid='progress-deleting'
                align='right'
              />
            }
          </div>
          : null
        }

        <ConfirmDialog
          data-testid='dialog-confirm'
          open={openDialog}
          title="Confirm Job Run Deletion?"
          text={`Are you sure you want to delete this run? (${props.job.id})`}
          onCancel={handleCancelDeleteClick}
          onConfirm={handleConfirmDeleteClick}
        />
      </Header>
    }
    detailsSlot={
      <JobRun
        data-testid='job-run'
        job={props.job}
      />
    }
  />
}

RunCard.propTypes = {
  deleting: PropTypes.bool,
  updatingId: PropTypes.string,
  officialJobId: PropTypes.string,
  job: PropTypes.shape({
    id: PropTypes.string,
    finished: PropTypes.bool,
    totalTime: PropTypes.number,
    fileProcessingTime: PropTypes.number,
    xpCalculatorRunTime: PropTypes.number,
    dataLakeLoadTime: PropTypes.number,
    valPolicySize: PropTypes.number,
    valTerminateSize: PropTypes.number,
    riskHistorySize: PropTypes.number,
    treatySize: PropTypes.number,
    errorMessage: PropTypes.string,
    createdDate: PropTypes.string,
    deletedDate: PropTypes.string,
    bases: PropTypes.arrayOf(PropTypes.string),
    aggregators: PropTypes.arrayOf(PropTypes.string),
    tableNames: PropTypes.arrayOf(PropTypes.string),
    parameters: PropTypes.shape({
      applyIncidenceRateCap: PropTypes.bool,
      addMonthlyAggregation: PropTypes.bool,
      applySubstandardFlatExtra: PropTypes.bool,
      applyExpectedLapseRates: PropTypes.bool,
      treatyRestrictions: PropTypes.string,
      policyRestrictions: PropTypes.string,
      observationDate: PropTypes.string,
      periodStartDate: PropTypes.string,
      periodEndDate: PropTypes.string
    })
  }).isRequired,
  onPopulate: PropTypes.func,
  onToggle: PropTypes.func,
  onDelete: PropTypes.func
}

/* istanbul ignore next */
RunCard.defaultProps = {
  deleting: false,
  updatingId: null,
  officialJobId: null,
  onPopulate: _job => {},
  onToggle: _job => {},
  onDelete: _job => {}
}

export default React.memo(RunCard)
