import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getRediDefaultDates } from '../../../store/pages/calculation/async'
import { setCalculationRedirectAction } from '../../../store/pages/global'
import { saveFinished } from '../../../store/pages/project/async'

const useFileUploadComplete = async () => {
  const global = useSelector(store => store.global)
  const project = useSelector(store => store.project)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const navigateToColumnMapping = React.useCallback(() => {
    navigate(`/columnmapping/${project.id}`)
  }, [navigate, project.id])

  React.useEffect(() => {
    const areFilesProcessed = () => {
      if (global.isSaving && global.isFileUploadComplete) {
        dispatch(saveFinished)
      }

      if (global.isReadyToRedirect && project.files.filter(x => x.profileStatus === 'SUCCEEDED').length > 0) {
        navigateToColumnMapping()
      }
      if (global.redirectCalculation) {
        dispatch(getRediDefaultDates)
        navigate(`/calculation/${project.id}`)
        dispatch({ ...setCalculationRedirectAction, payload: false })
      }
    }
    areFilesProcessed()
  }, [global, project, dispatch, navigateToColumnMapping, navigate])
}

export default useFileUploadComplete
