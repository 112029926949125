import sourcesApi from '../objects/sourcesAPI'
import postCalApi from '../objects/postCalApi'
import stepFunctionStatusLoop from './common/stepFunctionStatusLoop'
import calculationApi from '../objects/calculationApi'

export const loadingLapseCheckAction = {
  type: 'postcalc/LOADING_LAPSE_CHECK'
}

export const loadedLapseCheckAction = {
  type: 'postcalc/LOADED_LAPSE_CHECK'
}

export const loadingExpVsRiskAmountCheckAction = {
  type: 'postcalc/LOADING_EXP_VS_RISK_AMOUNT_CHECK'
}

export const loadedExpVsRiskAmountCheckAction = {
  type: 'postcalc/LOADED_EXP_VS_RISK_AMOUNT_CHECK'
}

export const loadingExpVsRiskCountCheckAction = {
  type: 'postcalc/LOADING_EXP_VS_RISK_COUNT_CHECK'
}

export const loadedExpVsRiskCountCheckAction = {
  type: 'postcalc/LOADED_EXP_VS_RISK_COUNT_CHECK'
}

export const loadingPolicyTrackerAction = {
  type: 'postcalc/LOADING_POLICY_TRACKER'
}

export const loadedPolicyTrackerAction = {
  type: 'postcalc/LOADED_POLICY_TRACKER'
}

export const loadingTerminatingClaimsAction = {
  type: 'postcalc/LOADING_TERMINATING_CLAIMS'
}

export const loadedTerminatingClaimsAction = {
  type: 'postcalc/LOADED_TERMINATING_CLAIMS'
}

export const loadingNonTerminatingClaimsAction = {
  type: 'postcalc/LOADING_NON_TERMINATING_CLAIMS'
}

export const loadedNonTerminatingClaimsAction = {
  type: 'postcalc/LOADED_NON_TERMINATING_CLAIMS'
}

export const loadingTerminatingOvsQClaimsAction = {
  type: 'postcalc/LOADING_TERMINATING_OvsQ_CLAIMS'
}

export const loadedTerminatingOvsQClaimsAction = {
  type: 'postcalc/LOADED_TERMINATING_OvsQ_CLAIMS'
}

export const loadingNonTerminatingOvsQClaimsAction = {
  type: 'postcalc/LOADING_NON_TERMINATING_OvsQ_CLAIMS'
}

export const loadedNonTerminatingOvsQClaimsAction = {
  type: 'postcalc/LOADED_NON_TERMINATING_OvsQ_CLAIMS'
}

export const loadingIbnrDataAction = {
  type: 'postcalc/LOADING_IBNR_DATA'
}

export const loadedIbnrDataAction = {
  type: 'postcalc/LOADED_IBNR_DATA'
}

export const replacingIbnrDataAction = {
  type: 'postcalc/REPLACING_IBNR_DATA'
}

export const setSelectedJobIdAction = {
  type: 'postcalc/SET_SELECTED_JOB_ID'
}

export const forcedRefreshPostCalcCheckDataAction = {
  type: 'postcalc/FORCED_REFRESH_POST_CALC_CHECK_DATA'
}

export const runningPostCalcCheckAction = {
  type: 'postcalc/RUNNING_POSTCALC_CHECK'
}

export const ranPostCalcCheckAction = {
  type: 'postcalc/RAN_POSTCALC_CHECK'
}

const initialState = {
  postCalculationCheckRunning: false,
  isPolling: false,
  lapseCheckData: null,
  errorLapse: false,
  lapseCheckLoading: false,
  latestRun: null,
  expVsRiskAmountCheckData: null,
  expVsRiskCountCheckData: null,
  errorExpVsRiskCheck: false,
  expVsRiskCheckLoading: false,
  policyTrackerData: null,
  errorPolicyTrackerCheck: false,
  policyTrackerLoading: false,
  claimsTerminatingData: null,
  claimsNonTerminatingData: null,
  claimsOvsQTerminatingData: null,
  claimsOvsQNonTerminatingData: null,
  errorClaimsValidation: false,
  claimsValidationLoading: false,
  ibnrDataLoading: false,
  ibnrData: null,
  errorIbnrData: false,
  forceRefresh: true,
  selectedJobId: 0,
  replacingIbnrData: false
}

const checkTypes = Object.freeze({
  EXP_VS_RISK_AMOUNT: 'riskHistoryAmount',
  EXP_VS_RISK_COUNT: 'riskHistoryCount',
  LAPSE: 'lapse',
  POLICY_TRACKER: 'policyTracker',
  TERMINATING_CLAIMS: 'terminatingClaims',
  NON_TERMINATING_CLAIMS: 'nonTerminatingClaims',
  TERMINATING_OVSQ_CLAIMS: 'term_OvsQ',
  NON_TERMINATING_OVSQ_CLAIMS: 'nonTerm_OvsQ',
  IBNR: 'ibnr'
})

function determineTableName (checkType, projectId, jobId) {
  const tableOptions = {
    [checkTypes.LAPSE]: `redi_${projectId}_postcal_${jobId}.lapse_check`,
    [checkTypes.POLICY_TRACKER]: `redi_${projectId}_postcal_${jobId}.policy_tracker_check`
  }

  return tableOptions[checkType]
}

function determineOptions (checkType, tableName, isMonthly, aggs) {
  const options = {
    [checkTypes.LAPSE]: { tableName },
    [checkTypes.POLICY_TRACKER]: { tableName },
    [checkTypes.IBNR]: { isMonthly, aggs }
  }

  return options[checkType]
}

export const fetchCheckDataWrapper = (loadingAction, checkType, loadedAction, loadingKey, dataKey, errorKey) => async (dispatch, getState) => {
  const {
    calculation: { lastSuccessfulRunId, latestSuccesfullRun, jobHistory },
    postCalc: { selectedJobId },
    project: { id: projectId }
  } = getState()

  dispatch({
    ...loadingAction,
    payload: {
      [loadingKey]: true
    }
  })

  const jobId = selectedJobId !== 0 ? selectedJobId : lastSuccessfulRunId
  const selectedjob = jobHistory.find(job => job.JobId === jobId)
  const isMonthly = selectedjob?.AddMonthlyAggregation ?? latestSuccesfullRun.AddMonthlyAggregation
  const aggs = selectedjob?.ibnrAggregators ?? latestSuccesfullRun.ibnrAggregators

  const tableName = determineTableName(checkType, projectId, jobId)
  const opts = determineOptions(checkType, tableName, isMonthly, aggs)

  // TODO: look to remove defensive coding

  if (projectId && jobId) {
    try {
      const data = await postCalApi.fetchCheckData(checkType)(projectId, jobId, opts)
      const hasResults = data.results !== undefined && data.results.length > 0
      dispatch({
        ...loadedAction,
        payload: {
          [dataKey]: hasResults ? data.results : [],
          [loadingKey]: false,
          [errorKey]: !hasResults
        }
      })
    } catch (e) {
      dispatch({
        ...loadedAction,
        payload: {
          [dataKey]: [],
          [loadingKey]: false,
          [errorKey]: true
        }
      })
    }
  }
}

export const replaceIbnr = (job, ibnrData, project, user) => async (dispatch, getState) => {
  dispatch({
    ...replacingIbnrDataAction,
    payload: {
      replacingIbnrData: true
    }
  })

  try {
    const { executionArn } = await calculationApi.replaceIbnr(job, ibnrData, project, user)

    const reloadIbnrData = async arn => {
      dispatch({
        ...replacingIbnrDataAction,
        payload: {
          replacingIbnrData: false
        }
      })
      return dispatch(getIbnrData)
    }

    return stepFunctionStatusLoop(
      executionArn,
      ({ status }) => ['FAILED', 'ABORTED', 'SUCCEEDED'].includes(status),
      reloadIbnrData
    )
  } catch (ex) {
    dispatch({
      ...replacingIbnrDataAction,
      payload: {
        replacingIbnrData: false
      }
    })
  }
}

export const getLapseData = fetchCheckDataWrapper(
  loadingLapseCheckAction,
  checkTypes.LAPSE,
  loadedLapseCheckAction,
  'lapseCheckLoading',
  'lapseCheckData',
  'lapseError'
)

export const getExpVsRiskAmountData = fetchCheckDataWrapper(
  loadingExpVsRiskAmountCheckAction,
  checkTypes.EXP_VS_RISK_AMOUNT,
  loadedExpVsRiskAmountCheckAction,
  'expVsRiskCheckLoading',
  'expVsRiskAmountCheckData',
  'errorExpVsRiskCheck'
)

export const getExpVsRiskCountData = fetchCheckDataWrapper(
  loadingExpVsRiskCountCheckAction,
  checkTypes.EXP_VS_RISK_COUNT,
  loadedExpVsRiskCountCheckAction,
  'expVsRiskCheckLoading',
  'expVsRiskCountCheckData',
  'errorExpVsRiskCheck'
)

export const getPolicyTrackerData = fetchCheckDataWrapper(
  loadingPolicyTrackerAction,
  checkTypes.POLICY_TRACKER,
  loadedPolicyTrackerAction,
  'policyTrackerLoading',
  'policyTrackerData',
  'errorPolicyTrackerCheck'
)

export const getTerminatingClaimsData = fetchCheckDataWrapper(
  loadingTerminatingClaimsAction,
  checkTypes.TERMINATING_CLAIMS,
  loadedTerminatingClaimsAction,
  'claimsValidationLoading',
  'claimsTerminatingData',
  'errorClaimsValidation'
)

export const getNonTerminatingClaimsData = fetchCheckDataWrapper(
  loadingNonTerminatingClaimsAction,
  checkTypes.NON_TERMINATING_CLAIMS,
  loadedNonTerminatingClaimsAction,
  'claimsValidationLoading',
  'claimsNonTerminatingData',
  'errorClaimsValidation'
)

export const getTerminatingOvsQClaimsData = fetchCheckDataWrapper(
  loadingTerminatingOvsQClaimsAction,
  checkTypes.TERMINATING_OVSQ_CLAIMS,
  loadedTerminatingOvsQClaimsAction,
  'claimsValidationLoading',
  'claimsOvsQTerminatingData',
  'errorClaimsValidation'
)

export const getNonTerminatingOvsQClaimsData = fetchCheckDataWrapper(
  loadingNonTerminatingOvsQClaimsAction,
  checkTypes.NON_TERMINATING_OVSQ_CLAIMS,
  loadedNonTerminatingOvsQClaimsAction,
  'claimsValidationLoading',
  'claimsOvsQNonTerminatingData',
  'errorClaimsValidation'
)

export const getIbnrData = fetchCheckDataWrapper(
  loadingIbnrDataAction,
  checkTypes.IBNR,
  loadedIbnrDataAction,
  'ibnrDataLoading',
  'ibnrData',
  'errorIbnrData'
)

export const redePostCalChecks = jobId => async (dispatch, getState) => {
  const {
    calculation: { runSetup: { parameters: modelInput } },
    project: { id }
  } = getState()
  if (jobId) {
    dispatch({
      ...runningPostCalcCheckAction,
      payload: {
        postCalculationCheckRunning: true
      }
    })
    try {
      const { executionArn } = await sourcesApi.startPostCalCheck(id, 'postCal', modelInput, jobId)

      const moveOnAfterPostCalcCheck = async arn => {
        const isSuccess = arn.status === 'SUCCEEDED'
        return dispatch({
          ...ranPostCalcCheckAction,
          payload: {
            postCalculationCheckRunning: false,
            postCalculationMessage: isSuccess ? 'SUCCEEDED' : 'FAILED',
            ...(isSuccess ? { forceRefresh: true } : null)
          }
        })
      }

      return stepFunctionStatusLoop(
        executionArn,
        ({ status }) => ['FAILED', 'ABORTED', 'SUCCEEDED'].includes(status),
        moveOnAfterPostCalcCheck
      )
    } catch (ex) {
      return dispatch({
        ...ranPostCalcCheckAction,
        payload: {
          postCalculationCheckRunning: false,
          postCalculationMessage: 'FAILED'
        }
      })
    }
  } else {
    console.info('no job to run postcal')
  }
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case loadingLapseCheckAction.type:
    case loadingExpVsRiskAmountCheckAction.type:
    case loadingExpVsRiskCountCheckAction.type:
    case loadingNonTerminatingClaimsAction.type:
    case loadingNonTerminatingOvsQClaimsAction.type:
    case loadingIbnrDataAction.type:
    case replacingIbnrDataAction.type:
    case loadingPolicyTrackerAction.type:
    case loadingTerminatingClaimsAction.type:
    case loadingTerminatingOvsQClaimsAction.type:
    case loadedLapseCheckAction.type:
    case loadedExpVsRiskAmountCheckAction.type:
    case loadedExpVsRiskCountCheckAction.type:
    case loadedNonTerminatingClaimsAction.type:
    case loadedNonTerminatingOvsQClaimsAction.type:
    case loadedPolicyTrackerAction.type:
    case loadedTerminatingClaimsAction.type:
    case loadedTerminatingOvsQClaimsAction.type:
    case loadedIbnrDataAction.type:
    case setSelectedJobIdAction.type:
    case forcedRefreshPostCalcCheckDataAction.type:
    case runningPostCalcCheckAction.type:
    case ranPostCalcCheckAction.type:
      return {
        ...state,
        ...payload
      }
    default:
      return state
  }
}
