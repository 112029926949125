import React from 'react'
import PropTypes from 'prop-types'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import { styled } from '@mui/material'

const Root = styled(MuiAccordion)({
  margin: '2px',
  marginBottom: '14px'
})

const AccordionSummary = styled(MuiAccordionSummary)(
  ({ isDisabled }) => ({
    margin: 0,
    padding: '8px',
    backgroundColor: isDisabled ? '#777777' : '#C5C5C5'
  })
)

const AccordionDetails = styled(MuiAccordionDetails)({
  padding: 0
})

export default function Accordion (props) {
  const handleClick = event => {
    event.preventDefault()
  }

  return <Root
    data-testid='root'
    expanded={props.expanded}
    onClick={handleClick}
  >
    <AccordionSummary isDisabled={props.isDisabled}>
      {props.summarySlot}
    </AccordionSummary>

    <AccordionDetails>
      {props.detailsSlot}
    </AccordionDetails>
  </Root>
}

Accordion.propTypes = {
  expanded: PropTypes.bool,
  summarySlot: PropTypes.node,
  detailsSlot: PropTypes.node
}

Accordion.defaultProps = {
  expanded: undefined,
  summarySlot: null,
  detailsSlot: null
}
