import PropTypes from 'prop-types'
import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import CurrentParametersCard from './CurrentParametersCard'
import RunCard from './RunCard'
import { styled } from '@mui/material'
import Pagination from '../Pagination'
import {
  getTestIdProp
} from '../../../../commonFunc'

const Root = styled('div')(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(4),
  backgroundColor: '#DEDEDE'
}))

export default function RunStudy (props) {
  const testIdProp = getTestIdProp(props);

  const activeJobs = props.jobs.filter(job => job.deletedDate === null)
  const deletedJobs = props.jobs.filter(job => job.deletedDate !== null)

  let jobsToBeDeleted = null
  if (activeJobs.length >= 3) {
    const officialJobIndex = activeJobs.map(job => job.id).indexOf(props.officialJobId)

    officialJobIndex <= 0
      ? jobsToBeDeleted = activeJobs.slice(2, activeJobs.length)
      : jobsToBeDeleted = activeJobs.slice(1, activeJobs.length)
  }
  jobsToBeDeleted = jobsToBeDeleted?.filter(job => job.id !== props.officialJobId)
  return (
    <Root {...testIdProp}>
      <CurrentParametersCard
        data-testid='parameters'
        studyIsRunning={!!props.submittedJob}
        disableActionButton={props.disableActionButton || !!props.errorMessage}
        tooltip={props.errorMessage || 'Run Study'}
        bases={props.value.bases}
        aggregators={props.value.aggregators}
        parameters={props.value.parameters}
        onRunStudy={props.onSubmit}
        onAbortStudy={props.onAbort}
        jobsToBeDeleted={jobsToBeDeleted}
        onDelete={props.onDelete}
        tag={props.tag}
        onHandleTagChange={props.onHandleTagChange}
      />

      {props.postCalcProgress && (
        <Grid container spacing={2} alignItems='center'>
          <Grid item>
            <Typography data-testid='postCalcCheck-status'>
              <strong>Post-Calculation Checks Progress:</strong>

              {props.postCalcProgress}
            </Typography>
          </Grid>

          <Grid item>
            <CircularProgress
              data-testid='postCalc-progress'
              align='right'
            />
          </Grid>
        </Grid>
      )}

      {props.submittedJob && (
        <RunCard
          data-testid='card-submitted'
          job={props.submittedJob}
        />
      )}

      {activeJobs.length > 0
        ? <Typography>
            <strong>Current Runs</strong>
          </Typography>
        : null
      }

      {activeJobs.map(job => (
        <RunCard
          data-testid={`card-item-${job.id}`}
          key={job.id}
          job={job}
          deleting={props.deletingIds.includes(job.id)}
          updatingId={props.updatingId}
          officialJobId={props.officialJobId}
          onPopulate={props.onPopulate}
          onToggle={props.onToggle}
          onDelete={props.onDelete}
        />
      ))}
      {
        deletedJobs.length > 0
          ? <>
              <Typography>
                <strong>Deleted Runs</strong>
              </Typography>
              <Pagination
                items={deletedJobs}
                itemsPerPage={3}
                renderItem={(job) => (
                <RunCard
                  data-testid={`card-item-${job.id}`}
                  key={job.id}
                  job={job}
                  deleting={props.deletingIds.includes(job.id)}
                  updatingId={props.updatingId}
                  officialJobId={props.officialJobId}
                  onPopulate={props.onPopulate}
                  onToggle={() => null}
                  onDelete={props.onDelete}
                  isDisabled={true}
                />
                )}
              />
            </>
          : null
      }
    </Root>
  )
}

const ParametersPropTypes = {
  applyIncidenceRateCap: PropTypes.bool,
  addMonthlyAggregation: PropTypes.bool,
  applySubstandardFlatExtra: PropTypes.bool,
  applyExpectedLapseRates: PropTypes.bool,
  treatyRestrictions: PropTypes.string,
  policyRestrictions: PropTypes.string,
  observationDate: PropTypes.string,
  periodStartDate: PropTypes.string,
  periodEndDate: PropTypes.string,
  powerBiReportUrl: PropTypes.string
}

RunStudy.propTypes = {
  updatingId: PropTypes.string,
  officialJobId: PropTypes.string,
  disableActionButton: PropTypes.bool,
  postCalcProgress: PropTypes.string,
  errorMessage: PropTypes.string,
  value: PropTypes.shape({
    bases: PropTypes.arrayOf(PropTypes.string),
    aggregators: PropTypes.arrayOf(PropTypes.string),
    parameters: PropTypes.shape(ParametersPropTypes)
  }),
  submittedJob: PropTypes.shape({
    id: PropTypes.string,
    totalTime: PropTypes.number,
    fileProcessingTime: PropTypes.number,
    xpCalculatorRunTime: PropTypes.number,
    dataLakeLoadTime: PropTypes.number,
    valPolicySize: PropTypes.number,
    valTerminateSize: PropTypes.number,
    riskHistorySize: PropTypes.number,
    treatySize: PropTypes.number,
    createdDate: PropTypes.string,
    deletedDate: PropTypes.string,
    bases: PropTypes.arrayOf(PropTypes.string),
    aggregators: PropTypes.arrayOf(PropTypes.string),
    tableNames: PropTypes.arrayOf(PropTypes.string),
    parameters: PropTypes.shape(ParametersPropTypes)
  }),
  jobs: PropTypes.arrayOf(PropTypes.object),
  deletingIds: PropTypes.arrayOf(PropTypes.string),
  onPopulate: PropTypes.func.isRequired,
  onAbort: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
}

/* istanbul ignore next */
RunStudy.defaultProps = {
  updatingId: null,
  officialJobId: null,
  disableActionButton: false,
  errorMessage: '',
  postCalcProgress: '',
  submittedJob: null,
  value: {
    bases: [],
    aggregators: [],
    parameters: {
      applyIncidenceRateCap: false,
      addMonthlyAggregation: false,
      applySubstandardFlatExtra: false,
      applyExpectedLapseRates: false,
      treatyRestrictions: '',
      policyRestrictions: '',
      observationDate: '',
      periodStartDate: '',
      periodEndDate: ''
    }
  },
  jobs: [],
  deletingIds: []
}
